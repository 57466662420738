import React, { useEffect, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { Divider, TextField } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../services/api';
import api_amr from '../../services/api_amr';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { CircleFlag } from 'react-circle-flags'
import { Chart } from 'react-google-charts';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import { getToken, checarPermissao, getId } from '../../utils/utils';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import clsx from "clsx";
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DialogContentText from '@material-ui/core/DialogContentText';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';


function TabPanelTab(props) {
  const { children, valueTab, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={valueTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {valueTab === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yPropsTabs(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function a11yProps2(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#A3CC51'
    },

  }
});
const themeTab = createTheme({
  palette: {
    primary: {
      main: '#A3CC51'
    },

  }
});


const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div style={{ width: '100%', height: '100', display: 'flex', justifyContent: 'center', alignItems: 'center', }
      }>
        <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
      </div>
    )
  );
};

function RetornaFormulas(props) {
  const [dustrol, setDustrol] = useState(false);
  const [inibidor, setInibidor] = useState(false);
  const [porto, setPorto] = useState('')
  const [portos, setPortos] = useState([])
  const [dolar, setDolar] = useState('')
  const [frete, setFrete] = useState(0)
  const [N, setN] = useState(0)
  const [P2O5, setP2O5] = useState(0)
  const [K2O, setK2O] = useState(0)
  const [jurousd, setJurousd] = useState(0)
  const [jurobr, setJurobr] = useState(0)
  const [vencimento, setVencimento] = useState((new Date(new Date().getFullYear(), new Date().getMonth() + 1,
    0)).toLocaleDateString('pt-BR'))
  const [pref, setPref] = useState([]);
  const [rem, setRem] = useState([]);
  const [micro, setMicro] = useState([]);
  const [materias, setMaterias] = useState('');
  const [micronutri, setMicronutri] = useState('');
  const [lastUpdate, setLastUpdate] = useState('');
  const [formulas, setFormulas] = useState('');
  const [formulas_default, setFormulas_default] = useState('')
  const [permitido, setPermitido] = useState(false)
  const [nome, setNome] = useState(false)
  const [openCol, setOpenCol] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [popupAdvConfig, setPopupAdvConfig] = useState(false);
  const [fechamentosManuais, setFechamentosManuais] = useState(false);
  const [fechamentos, setFechamentos] = useState(false);
  const [unique, setUnique] = useState([])
  const [carregandoHistorico, setCarregandoHistorico] = useState(false)
  const [chartData, setChartData] = useState('')
  const [N2, setN2] = useState(0)
  const [P2O52, setP2O52] = useState(0)
  const [K2O2, setK2O2] = useState(0)
  const [indexHistorico, setIndexHistorico] = useState(-1)// state para controlar qual card tem histórico calculado
  const [rawData, setRawData] = useState('')
  const [user, setUser] = useState(0)// implementado para liberar o histórico apenas para alessandro e adair

  const [showJuros, setShowJuros] = useState(false)
  const [showObgRemDust, setShowObgRemDust] = useState(false)
  const [showMicro, setShowMicro] = useState(false)
  const [abrirNegocio, setAbrirNegocio] = useState(false)
  const [dataJuro, setDataJuro] = useState(0)
  const [tipo, setTipo] = useState('')
  const classes = useStyles();

  //Usado para printar os nomes corretos dos elementos calculados, eles são puxados do banco como abreviações.
  const nomenclatura = {
    ca: 'Cálcio', s: 'Enxofre', mg: 'Magnésio', zn: 'Zinco', b: 'Boro',
    cu: 'Cobre', mn: 'Manganês', mo: 'Molibdênio', si: 'Silício', cl: 'Cloro'
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    async function fetchMyAPI() {
      setPermitido(await checarPermissao('retorna_formulas', props))
      let aux = getId()
      setUser(aux)
      console.log(aux)
      const response_micronutri = await api_amr.get('/return_micro_nutrientes', { headers: { 'x-api-key': getToken() } });
      setMicronutri(response_micronutri.data);


      const response_materias = await api_amr.get('/return_indice_materias', { headers: { 'x-api-key': getToken() } });
      setMaterias(response_materias.data);

      const response_return_portos = await api_amr.get('/return_portos', { headers: { 'x-api-key': getToken() } });
      setPortos(response_return_portos.data)


      const respose_update = await api_amr.get('/return_last_update', { headers: { 'x-api-key': getToken() } });
      setLastUpdate(respose_update.data);

      const respose_dolar_hora = await api.get('/DolarApi');

      setDolar(respose_dolar_hora.data)
      setValueTab(0)

    }
    fetchMyAPI();
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleClickOpen2 = () => {
    setPopupAdvConfig(true);
  };
  const handleClickClose2 = () => {
    setPopupAdvConfig(false);
  };
  const [isZoomed, setIsZoomed] = useState(false);
  const imgRef = useRef(null);

  const handleClick = () => {
    setIsZoomed(!isZoomed);
  };

  const handleOutsideClick = (event) => {
    if (imgRef.current && !imgRef.current.contains(event.target)) {
      setIsZoomed(false);
    }
  };
  const imgStyle = isZoomed
    ? {
      width: '50vw',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      cursor: 'zoom-out'
    }
    : {
      width: '200px',
      cursor: 'zoom-in'
    };
  async function getHistoricoFechamento(item, tipo = 'automatico') {

    setCarregandoHistorico(true)

    var historico_fechamento = ''
    if (tipo === 'manual') {
      let aux = item

      aux = JSON.stringify(aux)
      console.log(aux)
      try {
        historico_fechamento = await api_amr.get('/get_historico_fechamento', {
          params: { 'porto_id': porto.id, 'tipo': 'manual', aux }, headers: { 'x-api-key': getToken() }
        });
      } catch (error) {
        // Check if the error response status is 400

        alert('Não foi possível gerar o preço histórico dessa formulação!')
        setCarregandoHistorico(false)
        return

      }
    }
    else {
      let aux = formulas[item]
      aux.porto_id = porto.id
      aux.tipo = 'automatico'
      historico_fechamento = await api_amr.get('/get_historico_fechamento', {
        params: aux, headers: { 'x-api-key': getToken() }
      })
    };
    if (historico_fechamento.data === 'NOK') {
      alert('Não foi possível gerar o preço histórico dessa formulação!')
      setCarregandoHistorico(false)
      return
    }
    setTipo(tipo)
    setIndexHistorico(item)

    const rawData = historico_fechamento.data
    setRawData(rawData)
    const data = Object.keys(rawData.date).map((key) => ({
      date: rawData.date[key],
      price: rawData.price[key],
      price_rs: rawData.price_rs[key]
    }));

    let state = {
      labels: data.map((item) => item.date),
      options: {
        scales: {
          x: {
            grid: {
              display: true,
              drawBorder: true,
              drawOnChartArea: false
            }
          },
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'month',
              },
            },
          ],
          y1: {
            position: 'left',
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return '$ ' + value;
              }
            },
          },
          y2: {
            position: 'right',
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return 'R$ ' + value;
              }
            },
            grid: {
              display: false,
            },
          },
        }
      },
      datasets: [
        {
          label: 'Preço FOB Porto $',
          data: data.map((item) => item.price),
          fill: false,
          borderColor: '#A3CC51',
          backgroundColor: '#A3CC51',
          yAxisID: 'y1'
        },
        {
          label: 'Preço FOB Porto R$',
          data: data.map((item) => item.price_rs),
          fill: false,
          borderColor: 'blue',
          backgroundColor: 'blue',
          yAxisID: 'y2'

        },
      ],
    }
    setChartData(state)
    handleClickOpen3()
    setCarregandoHistorico(false)
  }
  const [open, setOpen] = useState(false);
  const handleClickOpen3 = () => {
    setOpen(true);
  };
  const handleClose3 = () => {
    setOpen(false);
  };
  async function getFormulas() {
    setFormulas('')
    setTipo('')
    setIndexHistorico(-1)
    setChartData('')
    setN2(N)
    setP2O52(P2O5)
    setK2O2(K2O)
    setDataJuro(vencimento)
    let auxPref = [];
    let auxRem = [];
    let auxMicro = [];
    pref.forEach((index) => (auxPref = [...auxPref, index.id]));
    rem.forEach((index) => (auxRem = [...auxRem, index.id]));
    Object.keys(micro).map((index) => (auxMicro = [...auxMicro, index + ':' + micro[index]])); // funciona com object.keys



    if (N === 0 && P2O5 === 0 && K2O === 0) {
      alert('Selecione os nutrientes!');
    } else if (porto === '') {
      alert('Selecione o porto de Origem!');
    } else {

      const response_formulas = await api_amr.get('/returnFormulas', {
        params: {
          nitrogenio: N,
          p2o5: P2O5,
          k2o: K2O,
          'porto': porto.id,
          dolar: dolar,
          frete: frete,
          pref: auxPref.toString(),
          rem: auxRem.toString(),
          add_micro: auxMicro.join(['-']),
          dustrol: dustrol.toString(),
          inibidor: inibidor.toString(),
          'valorJuroUSD': jurousd === '' ? 0.0 : jurousd,
          'valorJuroBR': jurobr === '' ? 0.0 : jurobr,
          'dataVencimento': vencimento
        }, headers: { 'x-api-key': getToken() }
      });
      let manuais = await getFechamentoManual()
      if ((response_formulas.data === 'NOK') & (manuais === 'NOK')) {
        alert('Não foi possível gerar formulas com essas configurações!');
      } else {
        if (response_formulas.data !== 'NOK') {
          setFormulas(response_formulas.data);
        }
        else {
          setFormulas('')
        }
        // if (response_formulas_default.data !== 'NOK') {
        //   setFormulas_default(response_formulas_default.data);
        // }
      }
    }
  }
  function convertNestedJSON(apiData) {
    const keys = Object.keys(apiData);
    const length = Object.keys(apiData[keys[0]]).length;
    const convertedData = [];

    for (let i = 0; i < length; i++) {
      const item = {};
      keys.forEach(key => {
        item[key] = apiData[key][i.toString()];
      });
      convertedData.push(item);
    }

    return convertedData;
  }
  function exportToCSV(apiData, fileName) {
    console.log(apiData);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const convertedData = convertNestedJSON(apiData);
    const ws = XLSX.utils.json_to_sheet(convertedData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  //Funcão de fechamentos manuais feitos pelo usuario na pagina de fechamentos padrao
  async function getFechamentoManual() {


    //Chamada para pegar fechamentos manuais
    const response_retorna_precificacao_fechamento = await api_amr.get('/retorna_precificacao_fechamento', {
      params: {
        nitrogenio: N,
        p2o5: P2O5,
        k2o: K2O,
        'porto': porto.id,
        dolar: dolar,
        frete: frete,

        dustrol: dustrol.toString(),
        inibidor: inibidor.toString(),
        'valorJuroUSD': jurousd === '' ? 0.0 : jurousd,
        'valorJuroBR': jurobr === '' ? 0.0 : jurobr,
        'dataVencimento': vencimento
      }, headers: { 'x-api-key': getToken() }
    });
    if (response_retorna_precificacao_fechamento.data === 'NOK') {
      setFechamentos(false)
      return 'NOK'
    }
    else {
      setFechamentos(true)
      setFechamentosManuais(response_retorna_precificacao_fechamento.data)
      let aux = [...new Set(response_retorna_precificacao_fechamento.data.map(item => item.id_formulacao))];
      setUnique(aux)

      return response_retorna_precificacao_fechamento
    }

  };

  function montaMicro(item, val) {

    let aux = micro;
    if (val >= 50) {

      aux[item] = val;
      setMicro(aux);

    } else {
      aux[item] = 0;
      setMicro(aux);
    }

  }

  // function retornaValorMicro(id){

  //     array.filter
  // }






  async function alterSelectedCard(index) {
    let aux = Array.from({ length: openCol.length }, i => i = false)
    aux[index] = true
    setOpenCol(JSON.parse(JSON.stringify(aux)))
  }

  async function cleanConfigAvancadas() {

    setJurousd(0)
    setJurobr(0)
    setVencimento((new Date(new Date().getFullYear(), new Date().getMonth() + 1,
      0)).toLocaleDateString('pt-BR'))
    setPref([])
    setRem([])
    setMicro([])
  }

  function generateColors(array_formulas) {
    // Define a list of colors
    const colorList = [
      'purple',
      'green',
      'orange',
      'magenta',
      'lime',
      'pink',
      'teal',
    ];

    return array_formulas.slice(1).map(([produto], index) => {
      if (produto.startsWith("KCL")) {
        return "red";
      } else if (produto.startsWith("UR")) {
        return "blue";
      } else {
        return colorList[index % colorList.length];
      }
    });
  }

  function montaArray(formulas_item) {
    let array_formulas = [["Produto", "Kg"]]
    Object.keys(formulas_item).map((item_produto, index_produto) =>
      (item_produto.includes('_peso') & formulas_item[item_produto] !== 0) ?
        array_formulas.push([((item_produto.split('#')[0] !== item_produto ? item_produto.split('#')[1] :
          item_produto.split('_')[0]).split('_')[0]), formulas_item[item_produto]])
        : null)
    return array_formulas
  }
  function montaArrayManual(formulas_item) {
    let array_formulas = [["Produto", "Kg"]]
    Object.keys(formulas_item).map((item_produto, index_produto) =>

      array_formulas.push([formulas_item[item_produto].abreviacao, formulas_item[item_produto].quantidade])
    )
    return array_formulas
  }
  const handleClickOpen = () => {
    setAbrirNegocio(true);
  };


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClickClose = () => {
    setAbrirNegocio(false);

  };

  async function saveFormulacaoCalculada(formula, calculada = true) {
    if (calculada === true) {
      let auxMicro = [];
      Object.keys(micro).map((index) => (auxMicro = [...auxMicro, index + ':' + micro[index]]));

      try {
        const response = await api_amr.get('/save_formulacao_calculada', {
          params: {
            formula,
            'porto_id': porto.id,
            add_micro: auxMicro.join(['-']),
            'calculada': 1
          },
          headers: { 'x-api-key': getToken() }
        });

        if (response.data.status === 'OK') {
          alert('Formulação salva com sucesso! Por favor, vá ao Dashboard360 para visualizar.');
        } else {
          alert('Erro ao salvar a formulação: ' + response.data.error);
        }
      } catch (error) {
        console.error('Erro ao salvar a formulação');
      }
    }
    else {
      try {
        const response = await api_amr.get('/save_formulacao_calculada', {
          params: {
            formula,
            'porto_id': porto.id,
            'calculada': 0
          },
          headers: { 'x-api-key': getToken() }
        });

        if (response.data.status === 'OK') {
          alert('Formulação salva com sucesso! Por favor, vá ao Dashboard360 para visualizar.');
        } else {
          alert('Erro ao salvar a formulação: ' + response.data.error);
        }
      } catch (error) {
        console.error('Erro ao salvar a formulação');
      }
    }

  }



  const retornaFechamentosId = (item) => {
    return fechamentosManuais.filter(function (el) { return el.id_formulacao == unique[item] })
  }

  const handleChange = (event, newValue) => { setValue(newValue) }
  const [value, setValue] = React.useState('1');
  const handleChangeTab = (event, newValue) => { setValueTab(newValue) }
  const [valueTab, setValueTab] = React.useState('1');
  const handleChange2 = (event, newValue) => { setValue2(newValue) }
  const [value2, setValue2] = React.useState('1');


  return (
    permitido ?
      <div style={{ flexDirection: 'row', display: 'flex', height: '95vh', overflow: 'hidden', }}>
        <div style={{
          width: '330px', height: '100%', background: 'rgba(248, 248, 248, 0.6)', borderRadius: 6,
          boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
        }}>

          <div style={{ display: 'flex', flexDirection: 'column' }}>

          </div>
          <div style={{ display: 'flex', flexDirection: 'row', }}>
            <h2 className={classes.inputh1} style={{ marginLeft: 10 }}> Retorna Fórmulas </h2>
          </div>

          < div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* Inputs esquerda */}

            < div style={{ marginTop: -10 }}>
              <b className={classes.input} style={{ marginLeft: 10, fontSize: 12, opacity: 0.6 }}> Matérias-primas atualizadas
                em: {lastUpdate} </b>
              < div >



                <div >
                  <b className={classes.input} style={{ marginLeft: 10, marginRight: 10, }}> Porto: </b>
                </div>
                <div>
                  < Autocomplete options={portos} getOptionLabel={(option) => (option.id + '-' +
                    option.nome_porto + '-' + option.cidade + '/' + option.UF)}
                    className={classes.autoCompleteFieldBig}
                    onChange={(e, v) => (setPorto(v), console.log(v))}
                    disableClearable
                    classes={{
                      input: classes.input,
                    }}
                    style={{ marginLeft: 10, marginTop: -1 }}
                    renderInput={(params) =>
                      <TextField {...params} placeholder="Selecionar Porto" variant='outlined' value={''}

                        InputProps={{
                          ...params.InputProps, disableUnderline: true, classes: {
                            notchedOutline: classes.noBorder
                          }
                        }} />}
                  />



                  < div style={{ marginTop: 5, }}>
                    <b className={classes.input} style={{
                      marginLeft: 10, marginRight: 10, marginTop: 5
                    }}> Dólar: </b>
                  </div>

                  < TextField placeholder="Dólar $" variant='outlined' onChange={(event) =>
                    setDolar(event.target.value)}
                    value={dolar}
                    className={classes.autoCompleteFieldBig}
                    style={{ marginLeft: '10px', marginTop: -1 }}
                    InputProps={{
                      disableUnderline: true,
                      classes: { notchedOutline: classes.noBorder }
                    }}
                  />

                  < div >
                    <Tooltip title="Valor em R$ do frete, da origem do produto até o destino final"
                      placement="top-end" >
                      <div style={{ marginTop: 5 }}>
                        <b className={classes.input} style={{
                          marginLeft: 10, marginTop: 5, marginRight: 10
                        }}> Frete (R$): </b>
                      </div>
                    </Tooltip>
                  </div>

                  < TextField placeholder="Valor Frete R$" variant='outlined' onChange={(event) =>
                    setFrete(event.target.value)}
                    className={classes.autoCompleteFieldBig}
                    value={frete}
                    style={{ marginLeft: '10px', marginTop: -1 }}
                    InputProps={{
                      disableUnderline: true,
                      classes: { notchedOutline: classes.noBorder }
                    }} />
                </div>

                < div className={classes.inputsDiv} >
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, }}>
                      <Tooltip
                        title="Quantidade de Nitrogênio da fórmula a ser calculada seguindo o modelo N-P-K, por exemplo: em um produto 15-12-10 o nitrogênio seria 15"
                        placement="top-end" >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <b className={classes.input}> Nitrogênio </b>
                        </div>
                      </Tooltip>
                    </div>

                    < TextField className={classes.autoCompleteFieldSmaller} placeholder="Digite..."
                      variant='outlined' onChange={(event) => setN(event.target.value)}
                      style={{ marginTop: -1, marginLeft: 10 }}

                      value={N}
                      InputProps={{
                        disableUnderline: true,
                        classes: { notchedOutline: classes.noBorder }
                      }}
                    />
                  </div>

                  <div style={{ marginLeft: -10 }}>
                    <div>
                      <Tooltip
                        title="Quantidade de Fósforo (P2O5) da fórmula a ser calculada seguindo o modelo N-P-K, por exemplo: em um produto 15-12-10 o Fósforo seria 12"
                        placement="top-end" >
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 15 }}>
                          <b className={classes.input}> Fósforo </b>
                        </div>
                      </Tooltip>
                    </div>

                    < TextField className={classes.autoCompleteFieldSmaller} placeholder="Digite..."
                      variant='outlined' value={P2O5} onChange={(event) => setP2O5(event.target.value)}
                      style={{ marginTop: -1, marginLeft: 10 }}

                      InputProps={{
                        disableUnderline: true,
                        classes: { notchedOutline: classes.noBorder }
                      }}
                    />
                  </div>

                  < div style={{ marginLeft: -10 }} >
                    <div>
                      <Tooltip
                        title="Quantidade de Potássio (K2O) da fórmula a ser calculada seguindo o modelo N-P-K, por exemplo: em um produto 15-12-10 o Potássio seria 10"
                        placement="top-end" >
                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 15 }}>
                          <b className={classes.input}> Potássio </b>
                        </div>
                      </Tooltip>
                    </div>
                    < TextField className={classes.autoCompleteFieldSmaller} placeholder="Digite..."
                      variant='outlined' value={K2O} onChange={(event) => setK2O(event.target.value)}
                      style={{ marginTop: -1, marginLeft: 10 }}
                      InputProps={{
                        disableUnderline: true,
                        classes: { notchedOutline: classes.noBorder }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={classes.inputsDiv} style={{ marginTop: -20, display: 'flex', flexDirection: 'row' }}>
                <div className={classes.calcDiv} style={{ marginLeft: 10 }}>
                  <Button onClick={() => (setValue(0), handleClickOpen2())} variant="contained"
                    className={classes.calcButton} > Configurações Avançadas </Button>
                </div>

                < div className={classes.calcDiv} >
                  <Button onClick={() => handleClickOpen()} variant="contained"
                    className={classes.calcButton} > Preços CFR </Button>
                </div>
                < div className={classes.calcDiv} style={{}}>
                  <Button onClick={() => (trackPromise(getFormulas()), getFechamentoManual())} variant="contained"
                    className={classes.calcButton} > Calcular </Button>
                </div>



              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: '40px' }}>
                <div style={{ marginBottom: '20px' }}>
                  <h3 className={classes.inputh1}>Corredores Logísticos</h3>
                </div>
                <img
                  ref={imgRef}
                  src="https://imagemr.s3.sa-east-1.amazonaws.com/Untitled.png"
                  alt="Corredores Logisticos Estrategicos Exportacao"
                  style={imgStyle}
                  onClick={handleClick}
                />
              </div>
              {/*Chuproski que fez: TEXTFIELD GAMBIARRA. O MUNDO DA PROGRAMAÇÃO É ESTRANHO, NÃO CONSEGUI FAZER ESSA BOX QUE TEM OS TEXTFIELD E OS BOTÕES FICAREM FIXOS NESSA M*RDA ENTÃO CRIEI UM TEXTFIELD COM TAMANHO QUE EU QUERIA E DEIXEI ELE INVISÍVEL E DISABLED PARA NINGUÉM PODER USAR E A P*RRA DO CARD FICAR FIXO*/}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  //label='Nome'
                  disabled
                  style={{ borderRadius: 6 }}
                  className={classes.fieldGambiarra}
                  onChange={(event) => setNome(event.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: { notchedOutline: classes.noBorder }
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              {/* FIM DO TEXTFIELD GAMBIARRA */}

            </div>
          </div>
          {/* Fim Inputs esquerda */}
          {/* Inputs Direita */}
        </div>

        {/* CARDS */}
        < div style={{ marginLeft: 30, width: '72vw', height: '80vh', borderRadius: 6, overflow: 'scroll', }}>

          <div>
            {/* DIV DAS TABS */}
            {formulas !== '' ?

              <div style={{ borderRadius: 6, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', background: "#FAFCF6", marginTop: '10px', width: "100%", display: 'flex', flexDirection: 'column', }}>
                {fechamentos ?
                  <div style={{
                    border: "1px solid #688233",
                    boxShadow: "0 0 5px #688233", borderRadius: 6, padding: 15, marginBottom: 10
                  }}>

                    <div>
                      <h3 className={classes.inputh1}>Formulações Padrão - {new Date().toLocaleDateString('pt-BR')}</h3>

                      {Object.keys(unique).map((item, index) =>
                        <div key={item} style={{ display: 'flex', justifyContent: 'center', paddingTop: 6 }}>
                          <div className={classes.card} style={{ borderRadius: 6, width: '95%', }} >
                            <div style={{ width: '90%', }}>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: -45, }}>
                                <div style={
                                  {
                                    display: 'flex', justifyContent: 'left', minWidth: '40%',
                                    alignContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '10px'
                                  }
                                }>
                                  <div className={classes.inputh1} style={{ display: 'flex', }}>
                                  </div>
                                  < div className={classes.inputh1} >
                                    {N2} - {P2O52} - {K2O2}
                                  </div>
                                </div>
                                < div style={{
                                  display: 'flex', justifyContent: 'center', minWidth: '60%',
                                  alignContent: 'center', alignItems: 'center',
                                }}>
                                  <h3 className={classes.inputh1}>
                                    <b className={classes.inputh1}> Total </b>
                                    < CircleFlag countryCode="us" height="20" style={{
                                      position: 'relative', top: 10, left:
                                        0
                                    }} />
                                    < CircleFlag countryCode="br" height="20" style={{
                                      position: 'relative', top: 0, right:
                                        10
                                    }} />

                                  </h3>
                                </div>
                              </div>
                              < div className={classes.notcontainer} style={{
                                display: 'flex', justifyContent: 'center',
                                minWidth: 330, alignItems: 'left', minHeight: 'fit-content'
                              }}>
                                <div style={{ minWidth: '100%', display: 'flex', flexDirection: 'row', }}>
                                  <div style={
                                    {
                                      minHeight: '10px', minWidth: '45%', justifyContent: 'space-evenly',
                                      display: 'flex', flexDirection: 'column', paddingLeft: '60px',
                                      paddingRight: '10px',
                                    }
                                  }>
                                    <div style={{ marginLeft: 80, marginTop: 50, height: '80%', overflow: 'auto' }}>
                                      {/* Unique vem aqui */}
                                      {
                                        Object.keys(retornaFechamentosId(item)).map((item_produto, index_produto) =>
                                          <div style={{
                                            marginTop: '-20px', display: 'flex', flexDirection: 'row',
                                            margingRight: '200px',
                                          }} key={item_produto} >
                                            <p className={classes.inputForm}>
                                              {retornaFechamentosId(item)[index_produto].abreviacao}
                                            </p>
                                            < p className={classes.inputForm} > {retornaFechamentosId(item)[index_produto].quantidade} kg </p>
                                          </div>
                                        )}
                                      {
                                        retornaFechamentosId(item)[0]['limitada'] !== undefined ? (
                                          (retornaFechamentosId(item)[0]['limitada'] !== '') | (retornaFechamentosId(item)[0]['limitada'].length !== 0) ? (
                                            <div style={{ paddingTop: 18 }
                                            }>
                                              <Tooltip title={retornaFechamentosId(item)[0]['limitada']}>
                                                <h4 className={classes.input}>
                                                  <ErrorOutlineIcon style={{ color: '#EED202' }} /> Compatibilidade Limitada!
                                                </h4>
                                              </Tooltip>
                                            </div>
                                          ) : null) : null}
                                    </div>
                                  </div>
                                  < div style={{
                                    minHeight: '100px', minWidth: '50%', justifyContent: 'space-evenly',
                                    display: 'flex', flexDirection: 'column', marginLeft: '0px', marginLeft: 5,
                                  }}>
                                    <div style={{ marginLeft: 5, marginTop: 5 }}>
                                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <b className={classes.inputForm} style={{ fontSize: '0.7em' }}>
                                          FCA(FOB PORTO) BIG BAG:
                                        </b>
                                        <p className={classes.inputForm} style={{ fontSize: '0.7em' }}>
                                          {
                                            retornaFechamentosId(item)[0]['fob_porto_bag_calculado'].toLocaleString('pt-br', {
                                              style: 'currency',
                                              currency: 'USD'
                                            })
                                          } -
                                          {(retornaFechamentosId(item)[0]['fob_porto_bag_rs_calculado']).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                          })}
                                        </p>
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <b className={classes.inputForm} style={{ fontSize: '0.7em' }}>
                                          FCA(FOB PORTO) GRANEL:
                                        </b>
                                        <p className={classes.inputForm} style={{ fontSize: '0.7em' }}>
                                          {
                                            retornaFechamentosId(item)[0]['fob_porto_granel_calculado'].toLocaleString('pt-br', {
                                              style: 'currency',
                                              currency: 'USD'
                                            })
                                          } -
                                          {(retornaFechamentosId(item)[0]['fob_porto_granel_rs_calculado']).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                          })}
                                        </p>
                                      </div>


                                      {
                                        retornaFechamentosId(item)[0]['frete'] !== 0 ?
                                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <b className={classes.inputForm} style={{ fontSize: '0.7em' }}> CPT (Frete incluso): </b>
                                            < p className={classes.inputForm} style={{ fontSize: '0.7em' }}>
                                              {
                                                (retornaFechamentosId(item)[0]['fob_porto_bag_calculado'] + retornaFechamentosId(item)[0]['frete_dol']).toLocaleString('pt-br', {
                                                  style:
                                                    'currency', currency: 'USD'
                                                })
                                              } -
                                              {(retornaFechamentosId(item)[0]['fob_porto_bag_rs_calculado'] + retornaFechamentosId(item)[0]['frete']).toLocaleString('pt-br', {
                                                style:
                                                  'currency', currency: 'BRL'
                                              })
                                              } </p>
                                          </div> : null}
                                      {dataJuro != (new Date(new Date().getFullYear(), new Date().getMonth() + 1,
                                        0)).toLocaleDateString('pt-BR') ?
                                        < div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                          <b className={classes.inputForm} style={{ fontSize: '0.7em' }}> Pagamento {dataJuro}: </b>
                                          < p className={classes.inputForm}style={{ fontSize: '0.7em' }} >
                                            {
                                              retornaFechamentosId(item)[0]['preco_total_cif_juros'].toLocaleString('pt-br', {
                                                style:
                                                  'currency', currency: 'USD'
                                              })
                                            } -
                                            {(retornaFechamentosId(item)[0]['preco_total_brl_cif_juros']).toLocaleString('pt-br', {
                                              style: 'currency', currency: 'BRL'
                                            })}</p>
                                        </div> : ''}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  < CardActions disableSpacing style={{ marginTop: '115px', marginRight: '70px' }}>
                                    <IconButton className={clsx(classes.expand, { [classes.expandOpen]: expanded, })}
                                      onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more" >
                                      <ExpandMoreIcon />
                                    </IconButton>
                                  </CardActions>
                                </div>
                              </div>
                              < Collapse in={expanded} timeout="auto" unmountOnExit >
                                <div style={{ marginLeft: 100 }} >

                                  {carregandoHistorico === false ? (
                                    <Button
                                      onClick={() => getHistoricoFechamento(retornaFechamentosId(item), 'manual')}
                                      variant="contained"
                                      className={classes.calcButton2}
                                    >
                                      Histórico de Preço da formulação
                                    </Button>
                                  ) : (
                                    <LoadingIndicator />
                                  )}

                                  {(rawData !== '') & (user == 138) ? (
                                    <Button
                                      onClick={() => exportToCSV(rawData, 'Historico Fechamento')}
                                      variant="contained"
                                      className={classes.calcButton2}
                                    >
                                      Baixar Histórico
                                    </Button>
                                  ) : (
                                    null
                                  )}


                                </div>
                                <CardContent>
                                  <div>
                                    {((chartData !== '') & (tipo === 'manual')) ?
                                      <div
                                        fullWidth={true}
                                        maxWidth={'md'}
                                      >

                                        <Line data={chartData} options={chartData.options} />

                                      </div>
                                      : null}
                                  </div>
                                  <div style={{
                                    display: 'flex', flexDirection: 'row',
                                  }}>
                                    <div>
                                      < div style={{
                                        marginLeft: 15, marginTop: 5, height: 'fit-content', overflow: 'auto', width: 200
                                      }}>
                                        <h3 className={classes.inputh1}> Teores </h3>
                                        < div style={{ display: 'flex', flexDirection: 'row', maxWidth: 200 }}>
                                          <p className={classes.inputForm}> Nitrogênio </p>
                                          < p className={classes.inputForm} > {(retornaFechamentosId(item)[0]['nitrogenio']).toFixed(1)} % </p>
                                        </div>
                                        < div style={{ display: 'flex', flexDirection: 'row', maxWidth: 200 }}>
                                          <p className={classes.inputForm}> Fósforo </p>
                                          < p className={classes.inputForm} > {(retornaFechamentosId(item)[0]['fosforo']).toFixed(1)} % </p>
                                        </div>
                                        < div style={{ display: 'flex', flexDirection: 'row', maxWidth: 200 }}>
                                          <p className={classes.inputForm}> Potássio </p>
                                          < p className={classes.inputForm} > {(retornaFechamentosId(item)[0]['potassio']).toFixed(1)} % </p>
                                        </div>
                                        {/* Esse OBJECT KEYS é dos micro nutrientes, não foi alterado nada no sprint Venosauro  */}
                                        {/* {
                                            Object.keys(retornaFechamentosId(item)[index_produto]).map((item_produto, index_produto) =>
                                              (item_produto.includes('_nutri') & retornaFechamentosId(item)[index_produto][item_produto] !== 0) ?
                                                <div style={{ display: 'flex', flexDirection: 'row', maxWidth: 200 }} key={item_produto} >
                                                  <p className={classes.inputForm}> {nomenclatura[((item_produto.split('#')[0] !==
                                                    item_produto ? item_produto.split('#')[1] :
                                                    item_produto.split('_')[0]).split('_')[0])]} </p>
                                                  < p className={classes.inputForm} > {((retornaFechamentosId(item)[index_produto][item_produto] /
                                                    10).toFixed(1))} % </p>
                                                </div> : null
                                            )} */}
                                        {/* Esse OBJECT KEYS é dos micro nutrientes, não foi alterado nada no sprint Venosauro  */}
                                      </div>
                                    </div >
                                    < div style={{ width: '80%' }} >
                                      <div style={{ marginLeft: 15, marginTop: 5, height: '100%', }}>
                                        <Chart chartType="PieChart" data={montaArrayManual(retornaFechamentosId(item))} options={{
                                          backgroundColor: '#FAFCF6',
                                          fontName: 'Poppins',
                                          fontSize: 18,
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                          title: 'Composição da Fórmula:',
                                          is3D: true,
                                          colors: generateColors(montaArrayManual(retornaFechamentosId(item)))
                                        }} width={"100%"} height={"100%"} />
                                      </div>
                                    </div>
                                  </div>
                                </CardContent>
                              </Collapse>
                            </div>
                            <div >
                              <div className={classes.calcButton3}>
                                <Button
                                  onClick={() => saveFormulacaoCalculada(fechamentosManuais, false)}
                                  variant="contained"
                                  className={classes.calcButtonSalvarF}
                                >
                                  Salvar Formulação
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>)}
                    </div>

                  </div> : null}


                <div style={{
                  border: "1px solid #A3CC51",
                  boxShadow: "0 0 5px #A3CC51", borderRadius: 6, padding: 15, marginBottom: 10
                }}>
                  <h3 className={classes.inputh1}>Formulações Calculadas  - {new Date().toLocaleDateString('pt-BR')}</h3>
                  {
                    Object.keys(formulas).map((item, index) =>
                      <div key={item} style={{
                        display: 'flex', justifyContent: 'center'
                      }}>
                        <div className={classes.card} style={{ borderRadius: 6, width: '95%', }} >
                          <div style={{ width: '90%', }}>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: -45, }}>
                              <div style={
                                {
                                  display: 'flex', justifyContent: 'left', minWidth: '40%',
                                  alignContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '10px'
                                }
                              }>
                                <div className={classes.inputh1} style={{ display: 'flex', }}>
                                </div>
                                < div className={classes.inputh1} >
                                  {N2} - {P2O52} - {K2O2}
                                </div>
                              </div>
                              < div style={{
                                display: 'flex', justifyContent: 'center', minWidth: '60%',
                                alignContent: 'center', alignItems: 'center',
                              }}>
                                <h3 className={classes.inputh1}>
                                  <b className={classes.inputh1}> Total </b>
                                  < CircleFlag countryCode="us" height="20" style={{
                                    position: 'relative', top: 10, left:
                                      0
                                  }} />
                                  < CircleFlag countryCode="br" height="20" style={{
                                    position: 'relative', top: 0, right:
                                      10
                                  }} />
                                </h3>
                              </div>
                            </div>
                            < div className={classes.notcontainer} style={{
                              display: 'flex', justifyContent: 'center',
                              minWidth: 330, alignItems: 'left', minHeight: 'fit-content'
                            }}>
                              <div style={{ minWidth: '100%', display: 'flex', flexDirection: 'row', }}>
                                <div style={
                                  {
                                    minHeight: '10px', minWidth: '45%', justifyContent: 'space-evenly',
                                    display: 'flex', flexDirection: 'column', paddingLeft: '60px',
                                    paddingRight: '10px',
                                  }
                                }>
                                  <div style={{ marginLeft: 80, marginTop: 50, height: '80%', overflow: 'auto' }}>
                                    {
                                      Object.keys(formulas[item]).map((item_produto, index_produto) =>
                                        (item_produto.includes('_peso') & formulas[item][item_produto] !== 0) ?
                                          <div style={{
                                            marginTop: '-20px', display: 'flex', flexDirection: 'row',
                                            margingRight: '200px',
                                          }} key={item_produto} >
                                            <p className={classes.inputForm}> {(item_produto.split('#')[0] !== item_produto
                                              ? item_produto.split('#')[1] : item_produto.split('_')[0]).split('_')[0]}
                                            </p>
                                            < p className={classes.inputForm} > {formulas[item][item_produto]} kg </p>
                                          </div> : null
                                      )}
                                    {
                                      formulas[item]['limitada'] !== undefined ? (
                                        (formulas[item]['limitada'] !== '') | (formulas[item]['limitada'].length !== 0) ? (
                                          <div style={{ paddingTop: 18 }
                                          }>
                                            <Tooltip title={formulas[item]['limitada']}>
                                              <h4 className={classes.input}>
                                                <ErrorOutlineIcon style={{ color: '#EED202' }} /> Compatibilidade Limitada!
                                              </h4>
                                            </Tooltip>
                                          </div>
                                        ) : null) : null}
                                  </div>
                                </div>
                                < div style={{
                                  minHeight: '100px', minWidth: '50%', justifyContent: 'space-evenly',
                                  display: 'flex', flexDirection: 'column', marginLeft: '0px', marginLeft: 5
                                }}>
                                  <div style={{ marginLeft: 5, marginTop: 5, }}>
                                    <h3 className={classes.inputh1}>
                                    </h3>
                                    < div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                      <b className={classes.inputForm}> FCA(FOB PORTO) BIG BAG: </b>
                                      < p className={classes.inputForm} >
                                        {
                                          formulas[item]['preco_total'].toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'USD'
                                          })
                                        } -
                                        {(formulas[item]['preco_total_brl']).toLocaleString('pt-br', {
                                          style:
                                            'currency', currency: 'BRL'
                                        })}</p>
                                    </div>
                                    < div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                      <b className={classes.inputForm}> FCA(FOB PORTO) GRANEL: </b>
                                      < p className={classes.inputForm} >
                                        {
                                          (formulas[item]['preco_total']-formulas[item]['custo_bag']).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'USD'
                                          })
                                        } -
                                        {(formulas[item]['preco_total_brl']-formulas[item]['custo_bag']).toLocaleString('pt-br', {
                                          style:
                                            'currency', currency: 'BRL'
                                        })}</p>
                                    </div>
                                    {
                                      formulas[item]['preco_total_cif'] !== formulas[item]['preco_total'] ?
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                          <b className={classes.inputForm}> CPT (Frete incluso): </b>
                                          < p className={classes.inputForm} >
                                            {
                                              formulas[item]['preco_total_cif'].toLocaleString('pt-br', {
                                                style:
                                                  'currency', currency: 'USD'
                                              })
                                            } -
                                            {(formulas[item]['preco_total_cif_brl']).toLocaleString('pt-br', {
                                              style:
                                                'currency', currency: 'BRL'
                                            })
                                            } </p>
                                        </div> : null}
                                    {dataJuro != (new Date(new Date().getFullYear(), new Date().getMonth() + 1,
                                      0)).toLocaleDateString('pt-BR') ?
                                      < div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <b className={classes.inputForm}> Pagamento {dataJuro}: </b>
                                        < p className={classes.inputForm} >
                                          {
                                            formulas[item]['preco_total_cif_juros'].toLocaleString('pt-br', {
                                              style:
                                                'currency', currency: 'USD'
                                            })
                                          } -
                                          {(formulas[item]['preco_total_brl_cif_juros']).toLocaleString('pt-br', {
                                            style: 'currency', currency: 'BRL'
                                          })}</p>
                                      </div> : ''}

                                  </div>
                                </div>
                              </div>
                              <div>
                                < CardActions disableSpacing style={{ marginTop: '115px', marginRight: '70px' }}>
                                  <IconButton className={clsx(classes.expand, { [classes.expandOpen]: expanded, })}
                                    onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more" >
                                    <ExpandMoreIcon />
                                  </IconButton>
                                </CardActions>
                              </div>
                            </div>
                            < Collapse in={expanded} timeout="auto" unmountOnExit >
                              <div style={{ marginLeft: 100 }} >

                                {carregandoHistorico === false ? (
                                  <Button
                                    onClick={() => getHistoricoFechamento(item)}
                                    variant="contained"
                                    className={classes.calcButton2}
                                  >
                                    Histórico de Preço da formulação
                                  </Button>
                                ) : (
                                  <LoadingIndicator />
                                )}
                                {(rawData !== '') & (user == 138) ? (
                                  <Button
                                    onClick={() => exportToCSV(rawData, 'Historico Fechamento')}
                                    variant="contained"
                                    className={classes.calcButton2}
                                  >
                                    Baixar Histórico
                                  </Button>
                                ) : (
                                  null
                                )}

                              </div>
                              <CardContent>
                                <div>
                                  {((chartData !== '') & (item === indexHistorico)) ?
                                    <div
                                      fullWidth={true}
                                      maxWidth={'md'}
                                    >

                                      <Line data={chartData} options={chartData.options} />

                                    </div>
                                    : null}
                                </div>
                                <div style={{
                                  display: 'flex', flexDirection: 'row',
                                }}>
                                  <div>
                                    < div style={{
                                      marginLeft: 15, marginTop: 5, height: 'fit-content', overflow: 'auto', width: 200
                                    }}>
                                      <h3 className={classes.inputh1}> Teores </h3>
                                      < div style={{ display: 'flex', flexDirection: 'row', maxWidth: 200 }}>
                                        <p className={classes.inputForm}> Nitrogênio </p>
                                        < p className={classes.inputForm} > {(formulas[item]['total_N'] /
                                          10).toFixed(1)} % </p>
                                      </div>
                                      < div style={{ display: 'flex', flexDirection: 'row', maxWidth: 200 }}>
                                        <p className={classes.inputForm}> Fósforo </p>
                                        < p className={classes.inputForm} > {(formulas[item]['total_P2O5'] /
                                          10).toFixed(1)} % </p>
                                      </div>
                                      < div style={{ display: 'flex', flexDirection: 'row', maxWidth: 200 }}>
                                        <p className={classes.inputForm}> Potássio </p>
                                        < p className={classes.inputForm} > {(formulas[item]['total_k2o'] /
                                          10).toFixed(1)} % </p>
                                      </div>
                                      {
                                        Object.keys(formulas[item]).map((item_produto, index_produto) =>
                                          (item_produto.includes('_nutri') & formulas[item][item_produto] !== 0) ?
                                            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: 200 }} key={item_produto} >
                                              <p className={classes.inputForm}> {nomenclatura[((item_produto.split('#')[0] !==
                                                item_produto ? item_produto.split('#')[1] :
                                                item_produto.split('_')[0]).split('_')[0])]} </p>
                                              < p className={classes.inputForm} > {((formulas[item][item_produto] /
                                                10).toFixed(1))} % </p>
                                            </div> : null
                                        )}
                                    </div>
                                  </div >
                                  < div style={{ width: '80%' }} >
                                    <div style={{ marginLeft: 15, marginTop: 5, height: '100%', }}>
                                      <Chart chartType="PieChart" data={montaArray(formulas[item])} options={{
                                        backgroundColor: '#FAFCF6',
                                        fontName: 'Poppins',
                                        fontSize: 18,
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        title: 'Composição da Fórmula:',
                                        is3D: true,
                                        colors: generateColors(montaArray(formulas[item])),
                                      }} width={"100%"} height={"100%"} />
                                    </div>
                                  </div>
                                </div>

                              </CardContent>
                            </Collapse>
                          </div>
                          <div >
                            <div className={classes.calcButton3}>
                              <Button
                                onClick={() => saveFormulacaoCalculada(formulas[item])}
                                variant="contained"
                                className={classes.calcButtonSalvarF}
                              >
                                Salvar Formulação
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>)
                  }
                </div>



              </div> :
              <LoadingIndicator />}
          </div >




        </div >


        <div >
          <Dialog
            disableBackdropClick="true"
            open={popupAdvConfig} fullWidth={true} maxWidth={'md'} onClose={handleClickClose2}
            aria-labelledby="form-dialog-title" >

            <DialogTitle id="form-dialog-title" > <b className={classes.inputh11}> Configurações Avançadas </b>
            </DialogTitle>
            < DialogContent >
              <DialogContentText>
                <div>


                  < Box sx={{ width: 'maxcontent' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <MuiThemeProvider theme={theme}>
                        <Tabs indicator centered indicatorColor="primary" value={value}
                          onChange={handleChange} >
                          <Tab title='' label={< b
                            className={classes.input} > Opções </b>} {...a11yProps(0)} />
                          <Tab title=''
                            label={< b className={classes.input} >
                              Micronutrientes </b>}{...a11yProps(1)} />

                        </Tabs>
                      </MuiThemeProvider>

                    </Box>


                    < TabPanel value={value} index={0} >

                      <div style={{
                        display: 'flex',
                        flexDirection: 'column', justifyContent: 'center', alignContent: 'center',
                        alignItems: 'center',
                      }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', }}>
                          <div style={{ paddingRight: 0 }}>

                            <div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <b className={classes.input}> Juro USD </b>
                              </div>
                            </div>

                            < TextField
                              className={classes.autoCompleteField}
                              placeholder="Digite..."
                              variant='outlined'
                              onChange={(event) => setJurousd(event.target.value)}
                              value={jurousd}
                              InputProps={{

                                disableUnderline: true,
                                classes: { notchedOutline: classes.noBorder }
                              }}

                            />
                          </div>
                          < div style={{ paddingRight: 0 }}>
                            <div>
                              <Tooltip title="Juro BRL" placement="top-end" >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <b className={classes.input}> Juro BRL </b>
                                </div>
                              </Tooltip>
                            </div>

                            < TextField
                              className={classes.autoCompleteField}
                              placeholder="Digite..."
                              variant='outlined'
                              value={jurobr}
                              onChange={(event) => setJurobr(event.target.value)}
                              InputProps={{

                                disableUnderline: true,
                                classes: { notchedOutline: classes.noBorder }
                              }}

                            />


                          </div>
                          < div style={{ paddingRight: 0 }}>
                            <div>
                              <Tooltip title="Data de Vencimento" placement="top-end" >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <b className={classes.input}> Data de Vencimento </b>
                                </div>
                              </Tooltip>
                            </div>
                            < TextField
                              type="date"
                              className={classes.autoCompleteField}
                              placeholder="Digite..."
                              variant='outlined'
                              value={vencimento}
                              onChange={(event) => setVencimento(event.target.value)}
                              InputProps={{

                                disableUnderline: true,
                                classes: { notchedOutline: classes.noBorder }
                              }}

                            />


                          </div>
                        </div>
                        < div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '10px' }}>


                          <div style={{}} >

                            <div>
                              <Tooltip title="Matérias-primas que devem ser utilizadas para fechamento " placement="top-end" >
                                <div style={{}}>
                                  <b className={classes.input}> Matérias obrigatórias nas fórmulas </b>
                                  < HelpOutlineIcon className={classes.toolTipIconStyle} />
                                </div>
                              </Tooltip>
                            </div>


                            < Autocomplete
                              multiple
                              value={pref}
                              getOptionLabel={(option) => option.id + '-' + option.abrev}
                              className={classes.autoCompleteFieldBig2}
                              options={materias}
                              onChange={(e, v) => setPref(v)}

                              classes={{
                                input: classes.input,

                              }}
                              renderInput={(params) => <TextField
                                {...params}
                                placeholder="Selecionar"
                                variant='outlined'
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                  classes: { notchedOutline: classes.noBorder }
                                }}

                              />}
                            />

                          </div>
                          < div style={{}} >



                            <div>
                              <Tooltip title="Matérias-primas que não deve ser utilizadas no calculo de fechamento" placement="top-end" >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <b className={classes.input}> Matérias para remover das fórmulas </b>
                                  < HelpOutlineIcon className={classes.toolTipIconStyle} />
                                </div>
                              </Tooltip>
                            </div>

                            < Autocomplete
                              multiple
                              value={rem}
                              getOptionLabel={(option) => option.id + '-' + option.abrev}
                              className={classes.autoCompleteFieldBig2}
                              options={materias}
                              onChange={(e, v) => setRem(v)}
                              classes={{
                                input: classes.input,

                              }}
                              renderInput={(params) => <TextField
                                {...params}
                                placeholder="Selecionar"
                                variant='outlined'
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                  classes: { notchedOutline: classes.noBorder }
                                }}

                              />}
                            />

                          </div>



                        </div>
                        < div className={classes.inputsDiv} style={{
                          marginLeft: '20px', display: 'flex'
                          , flexDirection: 'column', justifyContent: 'center',
                          alignContent: 'center', alignItems: 'center'
                        }}>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ paddingRight: 28, width: 360 }}>
                              <Checkbox checked={dustrol} onChange={() => setDustrol(!dustrol)}
                              />
                              < b className={classes.inputCheckbox} > Inserir Dustrol </b>
                            </div>
                            < div style={{ paddingRight: 28, width: 360 }}>

                              <Checkbox checked={inibidor} onChange={() => setInibidor(!inibidor)}
                                classes={{
                                  root: classes.root,
                                  checked: classes.checked,
                                }}
                              />
                              < b className={classes.inputCheckbox} > Inserir inibidor NBPT </b>
                            </div>
                          </div>
                          < div >

                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                            </div>


                          </div>

                        </div>


                      </div>

                    </TabPanel>

                    < TabPanel value={value} index={1} >


                      <div style={
                        {
                          display: 'flex', flexDirection: 'column', justifyContent: 'center',
                          alignContent: 'center', alignItems: 'center',
                        }
                      }>

                        < div style={{ fontFamily: 'Poppins', fontSize: '11px', }}> (Valor mínimo: 50 kg)</div>
                        {
                          Object.keys(micronutri.slice(0, micronutri.length / 2)).map((item, index) => (
                            <div className={classes.inputsDiv} id={item} key={item} style={{
                              display: 'flex'
                              , flexDirection: 'row', justifyContent: 'center', alignContent: 'center',
                              alignItems: 'center'
                            }}>

                              <div className={classes.nutriDiv}>
                                <div>
                                  <Tooltip
                                    title="Matérias-primas que não deve ser utilizadas no calculo de fechamento"
                                    placement="top-end" >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      <b className={classes.input}> {
                                        micronutri[item * 2].descNome
                                          === 'SSP micrado - Base Micrada' ? 'BM 02-18-00+2%Zn+ 1 % B + 1 % Cu + 2 % Mn' : micronutri[item * 2].descNome
                                      } </b>
                                    </div>
                                  </Tooltip>
                                </div>

                                < TextField
                                  value={micro[micronutri[item * 2].id]}
                                  placeholder='0'
                                  className={classes.autoCompleteField}
                                  variant='outlined'
                                  id={micronutri[item * 2].abrev}
                                  onChange={(evt) => montaMicro(micronutri[item * 2].id, evt.target.value)}
                                  InputProps={{
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder }
                                  }}
                                />
                              </div>
                              < div className={classes.nutriDiv} >
                                <div>
                                  <Tooltip
                                    title="Matérias-primas que não deve ser utilizadas no calculo de fechamento"
                                    placement="top-end" >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      <b className={classes.input}> {
                                        micronutri[item * 2 +
                                          1].descNome === 'SSP micrado - Base Micrada' ? 'BM02-18-00+2%Zn +1%B +1%Cu +2%Mn' : micronutri[item * 2 + 1].descNome
                                      } </b>

                                    </div>
                                  </Tooltip>
                                </div>
                                < TextField placeholder='0' className={classes.autoCompleteField}
                                  value={micro[micronutri[item * 2 + 1].id]}
                                  variant='outlined' id={micronutri[item * 2 + 1].abrev}
                                  onChange={(evt) => montaMicro(micronutri[item * 2 + 1].id, evt.target.value)}
                                  InputProps={{

                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder }
                                  }}

                                />
                              </div>
                            </div>
                          ))}

                      </div>
                    </TabPanel>
                  </Box>


                </div>
              </DialogContentText>


            </DialogContent>
            < DialogActions style={{ marginLeft: 15, justifyContent: 'center', }}>
              <Button onClick={() => (handleClickClose2(), cleanConfigAvancadas())} className={classes.calcButtonTab} style={{ marginRight: 20 }}>
                Limpar
              </Button>

              < Button onClick={() => (handleClickClose2())} className={classes.calcButtonTab} style={{ marginLeft: 20 }}>
                Salvar
              </Button>

            </DialogActions>
          </Dialog>
        </div>
        {
          abrirNegocio !== false ?
            <Dialog open={abrirNegocio} onClose={handleClickClose}
              aria-labelledby="form-dialog-title" >
              <DialogTitle id="form-dialog-title" >
                <h2 className={classes.input}> Tabela de Preços CFR $ / Ton </h2>
              </DialogTitle>
              < DialogContent >
                <div>

                  {materias !== '' ? (
                    <div style={{
                      padding: 10, width: 400, border: 'solid',
                      borderWidth: 1, borderColor: 'lightgrey', borderRadius: 6,
                    }}>
                      {
                        Object.keys(materias).map((index) =>
                          index % 2 === 0 ? (
                            <div style={{ display: 'flex', borderRadius: 6 }}  >

                              <p style={{ width: 210, marginLeft: 15, }}
                                className={classes.input} > {materias[index].descNome}
                              </p>
                              < span > {' '} </span>
                              < p className={classes.input} > $ {materias[index].price}
                              </p>
                            </div>
                          ) : (
                            <div style={{
                              display: 'flex', backgroundColor: '#A3CC51',
                              borderRadius: 6
                            }} key={index}>

                              <p className={classes.input} style={{
                                width: 210,
                                marginLeft: 15,
                              }}> {materias[index].descNome} </p>
                              < span > {' '} </span>
                              < p className={classes.input} > $ {materias[index].price}
                              </p>
                            </div>
                          )
                        )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>


              </DialogContent>
              < DialogActions >
                <Button onClick={(handleClickClose)} className={classes.calcButton}
                  style={{ marginRight: 20 }}>
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>
            : null}
      </div >
      :
      <LoadingIndicator />
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 100,
  },
  inputh3: {

    fontFamily: 'Poppins',
    fontWeight: 'bold',
    color: '#383838',
    fontSize: '12px',
  },
  inputh4: {

    fontFamily: 'Poppins',
    color: '#383838',
    fontSize: '12px',
  },
  inputsDiv: {
    display: 'flex',
    width: '100%',
    marginTop: 10,

  },
  noBorder: {
    border: "none",
  },
  autoCompleteFieldSmaller: {
    marginLeft: 15,
    marginTop: 10,
    width: '80%',
    borderRadius: 6,
    backgroundColor: 'white',
    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0,0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
  },
  autoCompleteFieldSmall: {
    backgroundColor: 'white',
    marginTop: 10,
    minWidth: 145,
    maxWidth: 145,
    borderRadius: 6,
    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
  },
  autoCompleteField: {

    marginTop: 10,
    minWidth: 228,
    maxWidth: 228,
    borderRadius: 6,
    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
  },
  autoCompleteFieldBig: {
    backgroundColor: 'white',

    marginRight: 10,
    marginTop: 10,
    width: '93%',
    borderRadius: 6,
    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

  },
  autoCompleteFieldBig2: {
    backgroundColor: 'white',

    marginRight: 0,
    marginTop: 10,
    width: 360,
    borderRadius: 6,
    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

  },
  fieldGambiarra: {
    backgroundColor: '0px 4px 4px rgba(0, 0, 0, 1)',
    width: '330px',
    height: '50px',

  },

  textField: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    width: '352px',
    backgroundColor: 'white',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItens: 'center',
    borderRadius: 6,
    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
  },
  input: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#383838',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  inputCheckbox: {

    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#000000'
  },
  inputh1: {
    fontFamily: 'Poppins',

    color: '#383838'
  },
  inputh11: {
    fontFamily: 'Poppins',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    color: '#383838',
    display: 'flex',
  },
  root: {
    "&$checked": {
      color: "black",
      backgroundColor: 'white'
    }
  },
  checked: {
    borderColor: 'black'
  },

  calcButton: {
    fontFamily: 'Poppins',
    height: '75%',
    fontSize: '9px',
    background: '#A3CC51',
    width: '91%',
    color: 'white',
    borderRadius: 6,
    '&:hover': {
      background: "rgba(163, 204, 81, 0.7)",
    },
  },

  calcButton2: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    background: '#A3CC51',
    width: 150,
    color: 'white',
    borderRadius: 6,
    '&:hover': {
      background: "rgba(163, 204, 81, 0.7)",
    },
  },
  calcButtonSalvarF: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    background: '#A3CC51',
    width: 140,
    margin: 20,
    marginRight: 30,
    color: 'white',
    borderRadius: 6,
    '&:hover': {
      background: "rgba(163, 204, 81, 0.7)",
    },
  },

  calcButtonTab: {
    fontFamily: 'Poppins',
    height: '75%',
    fontSize: '10px',
    background: '#A3CC51',
    width: '15%',
    color: 'white',
    borderRadius: 6,
    '&:hover': {
      background: "rgba(163, 204, 81, 0.7)",
    },
  },


  calcDiv: {
    marginTop: 40,
    width: '100%',
    height: 50,

  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  nutriDiv: {
    paddingRight: 28,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 228
  },
  inputForm: {
    width: '50%',
    fontFamily: 'Poppins',
    color: '#383838'
  },
  toolTipIconStyle: {
    marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
  },

  card: {

    boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',

    marginBottom: 10,
    minWidth: 528,
    borderRadius: 6,
    flexDirection: 'row',
    display: 'flex',
    height: 'fit-content',

  },
  calcButton3: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
  },

}));

export default RetornaFormulas;